import React from 'react';
import NavBar from '../components/NavBar';
import Contact from '../components/Contact';
import SectionContent from '../components/SectionContent';

const Section = () => {
  return (
    <div>
        <NavBar />
        <SectionContent />
        <Contact />
    </div>
  )
}

export default Section;