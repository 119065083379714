import React from "react";
import { Routes, Route} from 'react-router-dom';
import Home from "./Pages/Home";
import About from "./Pages/About";
import ContactUs from "./Pages/ContactUs";
import News from "./Pages/News";
import Section from "./Pages/Section";
import Programs from "./Pages/Programs";

function App() {
  return (
    <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='about-us' element={<About />}></Route>
        <Route path='contact-us' element={<ContactUs />}></Route>
        <Route path='news&events' element={<News />}></Route>
        <Route path='section' element={<Section />}></Route>
        <Route path='programs' element={<Programs />}></Route>
    </Routes>
   
  );
}

export default App;
