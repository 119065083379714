import news1 from '../assets/news1.png';
import news2 from '../assets/news2.png';


export const swiper = [
    {
        id: 0,
        title: 'Temple secondary school hosted group 4 of the 2018 akintola williams national youth debate',
        date: 'By Admin on Nov 23rd, 2020',
        day: '1 day ago',
        mssg: 'Lorem ipsum dolor, sit amet conse ctetur adipisicing elit. Consequuntur....',
        src: news1
    },
    {
        id: 1,
        title: 'Temple secondary school hosted group 4 of the 2018 akintola williams national youth debate',
        date: 'By Admin on Nov 23rd, 2020',
        day: '2 day ago',
        mssg: 'Lorem ipsum dolor, sit amet conse ctetur adipisicing elit. Consequuntur....',
        src: news2
    },
    {
        id: 2,
        title: 'Temple secondary school hosted group 4 of the 2018 akintola williams national youth debate',
        date: 'By Admin on Nov 23rd, 2020',
        day: '3 day ago',
        mssg: 'Lorem ipsum dolor, sit amet conse ctetur adipisicing elit. Consequuntur....',
        src: news1
    },
    {
        id: 3,
        title: 'Temple secondary school hosted group 4 of the 2018 akintola williams national youth debate',
        date: 'By Admin on Nov 23rd, 2020',
        day: '4 day ago',
        mssg: 'Lorem ipsum dolor, sit amet conse ctetur adipisicing elit. Consequuntur....',
        src: news1
    },
    {
        id: 3,
        title: 'Temple secondary school hosted group 4 of the 2018 akintola williams national youth debate',
        date: 'By Admin on Nov 23rd, 2020',
        day: '5 day ago',
        mssg: 'Lorem ipsum dolor, sit amet conse ctetur adipisicing elit. Consequuntur....',
        src: news1
    },
    {
        id: 3,
        title: 'Temple secondary school hosted group 4 of the 2018 akintola williams national youth debate',
        date: 'By Admin on Nov 23rd, 2020',
        day: '6 day ago',
        mssg: 'Lorem ipsum dolor, sit amet conse ctetur adipisicing elit. Consequuntur....',
        src: news1
    },
];


export const swiperalumna = [
    {
        id: 0,
        img: '',
        mssg: 'I am glad to have been given this opportunity to share my experiences and my journey through Temple School. I have a bad habit of always only looking straight and forward, so it was personally refreshing to sit down and reminisce about past moments that helped define the person I am today.I would like to urge anyone reading this to explore different options (academically and extracurricular-wise) to find thosethings you truly enjoy and are willing to strive for excellence towards, as that is what Temple School, in my experience,promotes; many varities of opportunities to shine brightly. This is the one thing that I have been made to take to heart, havingleft the secondary school environment and continue to do so.',
        name: 'Chukwunonso Nzenwa',
        desc: 'Reaching for Greater Heights',
        rating: '4.8'
    },
    {
        id: 1,
        img: '',
        mssg: 'I am glad to have been given this opportunity to share my experiences and my journey through Temple School. I have a bad habit of always only looking straight and forward, so it was personally refreshing to sit down and reminisce about past moments that helped define the person I am today.I would like to urge anyone reading this to explore different options (academically and extracurricular-wise) to find thosethings you truly enjoy and are willing to strive for excellence towards, as that is what Temple School, in my experience,promotes; many varities of opportunities to shine brightly. This is the one thing that I have been made to take to heart, havingleft the secondary school environment and continue to do so.',
        name: 'Nzenwa Chukwunonso',
        desc: 'Reaching for Greater Heights',
        rating: '5.0'
    },
    {
        id: 2,
        img: '',
        mssg: 'I am glad to have been given this opportunity to share my experiences and my journey through Temple School. I have a bad habit of always only looking straight and forward, so it was personally refreshing to sit down and reminisce about past moments that helped define the person I am today.I would like to urge anyone reading this to explore different options (academically and extracurricular-wise) to find thosethings you truly enjoy and are willing to strive for excellence towards, as that is what Temple School, in my experience,promotes; many varities of opportunities to shine brightly. This is the one thing that I have been made to take to heart, havingleft the secondary school environment and continue to do so.',
        name: 'Nzenwa Chukwu',
        desc: 'Reaching for Greater Heights',
        rating: '4.5'
    },
]
