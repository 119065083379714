import React from 'react'
import NavBar from '../components/NavBar';
import NewsContent from '../components/NewsContent';

const News = () => {
  return (
    <div>
        <NavBar />
        <NewsContent />
    </div>
  )
}

export default News;